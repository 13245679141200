/*
    Use the command "npm install" in the theme directory to install these packages
    Make sure to use
    npm run dev
    to start the server
*/

import $ from "jquery";

import "slick-carousel/slick/slick";

// core version + navigation, pagination modules:
import Swiper, {
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Scrollbar,
  Controller,
  EffectCoverflow
} from 'swiper/swiper-bundle.js';
// remove /js/ in wp version

// configure Swiper to use modules
Swiper.use([Navigation, Thumbs, Controller, Pagination, Autoplay]);
import 'swiper/swiper-bundle.min.css';

window.$ = $;
window.jQuery = $;

// slider
// ---------------------------------------------------------------------------------------------------
 


var textimgslide = new Swiper(".textimgslide", {
  // Optional parameters
  loop:true,

  pagination: {
    el: ".textimgslide .swiper-pagination",
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: ".textimgslide .swiper-button-next",
    prevEl: ".textimgslide .swiper-button-prev",
  },
});


var treatmentsslide = new Swiper(".treatmentsslide", {
  // Optional parameters
  loop:true,

  pagination: {
    el: ".treatmentsslide .swiper-pagination",
    clickable: true,
  },
  // Navigation arrows
  navigation: {
    nextEl: ".treatmentsslide .swiper-button-next",
    prevEl: ".treatmentsslide .swiper-button-prev",
  },
});

var swiper = new Swiper(".mySwiper", {
  slidesPerView: 3,
  spaceBetween: 30,
  slidesPerGroup: 1,
  loop: true,
  loopFillGroupWithBlank: true,
  // noSwiping: true,
  // noSwipingClass: 'swiper-slide',
  autoplay: {
    delay: 10000,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 500px

    // when window width is >= 1000px
    1000: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      // slidesPerColumn: 2,
      spaceBetween: 30,
      loop: true,
      loopFillGroupWithBlank: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      }
    },
    700: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      spaceBetween: 30,
      loop: true,
      loopFillGroupWithBlank: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      }
    },

    600: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
      loop: true,
      loopFillGroupWithBlank: true,
      noSwiping: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },


    },
    380: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
      loop: true,
      loopFillGroupWithBlank: true,
      noSwiping: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    },
    350: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
      loop: true,
      loopFillGroupWithBlank: true,
      noSwiping: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    },
  }
});







// Fade in animations
// ---------------------------------------------------------------------------------------------------
var callback = function() {
  // Handler when the DOM is fully loaded
  var t = document.querySelectorAll(".rvl");

  t.forEach(function(n) {
    n.getBoundingClientRect().top < window.innerHeight / 1.3 &&
      n.classList.add("animate");
  });
};

if (
  document.readyState === "complete" ||
  (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  callback();
} else {
  document.addEventListener("DOMContentLoaded", callback);
}

function aniMate(n) {
  var t = document.querySelectorAll(n);

  window.addEventListener("scroll", function() {
    t.forEach(function(n) {
      n.getBoundingClientRect().top < window.innerHeight / 1.3 &&
        n.classList.add("animate");
    });
  });
}

aniMate(
  ".rvl,.imageanimleft, .fade-up-stop , .fade-right-stop, .fade-left-stop ,.tanbox,.greybox .fade-in , .fade-in-left , .fade-in-right , .scale-down"
);

// Scroll to top button
// ---------------------------------------------------------------------------------------------------
$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('#scroll').fadeIn();
    } else {
      $('#scroll').fadeOut();
    }
  });
  $('#scroll').click(function() {
    $('html, body').animate({ scrollTop: 0 }, 600);
    return false;
  });
});

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $('#header-container').addClass('act');
    } else {
      $('#header-container').removeClass('act');
    }
  });
});

// Menu Functionality
// ---------------------------------------------------------------------------------------------------
// Menu Functionality
// ---------------------------------------------------------------------------------------------------
$(document).ready(function() {
  let menuopen = false;

  $('.menu-button').click(function(e) {
    if (menuopen == false) {
      $('.hasdropdown').removeClass('activeitem');

      $(this).addClass('activeitem');
      $('.menu-text').text('Close');
      $('.navbaritems,.navdiv,#header-container,.bars').addClass('activemenu');
      // if ($(window).width() > 992) {
      //   $('#hvr').addClass('animamenu');
      // }

      menuopen = true;
    } else {
      $('.hasdropdown').addClass('activeitem');
      $('.menu-text').text('Menu');
      $(this).removeClass('activeitem');
      $('.navbaritems,.navdiv,#header-container,.bars').removeClass('activemenu');
      menuopen = false;
    }
  });

  if ($(window).width() > 992) {
    $.fn.accessibleDropDown = function() {
      var el = $(this);

      $('.hasdropdown  a', el)
        .click(function () {
          $('.hasdropdown').removeClass('animamenu');

          $(this)
            .parents('.hasdropdown')
            // .addClass('animamenu');
        })


        .blur(function() {
          $(this)
            .parents('.hasdropdown')
            .removeClass('animamenu');
        });
    };

    $('ul.items').accessibleDropDown();
  }

  $('.closemenubutton').click(function(e) {
    $('.hasdropdown').addClass('activeitem');
    $('.menu-text').text('Menu');
    $(this).removeClass('activeitem');
    $('.navbaritems,.navdiv,#header-container').removeClass('activemenu');
    menuopen = false;
  });

  // mobile menu click

  if ($(window).width() < 992) {
    $('ul#menu-main-menu  .hasdropdown > a').click(function(e) {
      e.preventDefault();
    });

    $('.hasdropdown').click(function(e) {
      e.stopPropagation();
      if ($(this).hasClass('animamenu')) {
        $(this).removeClass('animamenu');
      } else {
        $(this).addClass('animamenu');
      }
    });
  }
});


if ($(window).width() > 992) {
  $('ul.items').on('mouseenter', function () {
    // $('li#hvr').removeClass('animamenu');
  });



  $('ul.items > .hasdropdown').on('click', function () {
    $('.hasdropdown').removeClass('animamenu');
    // $(this).addClass('animamenu');
  });

}
if ($(window).width() < 992) {
  $('.hasdropdown > a').on('click', function (e) {
    e.preventDefault();
  });
}
// form submit
// ---------------------------------------------------------------------------------------------------
$("#schedule").submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var form_results = $("#form-results");

  form_results.html(" ");
  form_results.removeClass("alert");
  form_results.removeClass("alert-error");
  form_results.removeClass("alert-success");

  form.find(".btn").prop("disabled", true);

  var errors = [];

  // Validation
  if (form.find("input[name=name]").val() == "") {
    errors.push("The name field is required");
  }
  if (form.find("input[name=email]").val() == "") {
    errors.push("The email field is required");
  }
  if (!form.find('select[name="preferred_day"]').val()) {
    errors.push("The day of the week field is required");
  }
  if (!form.find('select[name="preferred_time"]').val()) {
    errors.push("The time of day field is required");
  }

  if (errors.length > 0) {
    var error_html = '<ul class="mb-0">';
    form_results.addClass("alert");
    form_results.addClass("alert-info");

    $.each(errors, function (index, value) {
      error_html += "<li>" + value + "</li>";
    });
    error_html += "</ul>";

    form_results.html(error_html);
    form.find(".btn").prop("disabled", false);
    return false;
  }

  var data = {
    action: 'do_ajax',
    fn: 'schedule',
    data: form.serializeArray(),
    security: the_theme.ajax_nonce,
    siteurl: the_theme.url,
  };



  $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {

    response = JSON.parse(response);

    console.log(response);

    $("#form-results").hide(0);

    $(".formpwrap").fadeOut(function () {
      form_results.append(response);
      setTimeout(function () {
        $("#form-results").fadeIn();
      }, 600);
    });

    $(form).each(function () {
      this.reset();
    });

    form.find('.btn').prop('disabled', false);

    if (response.type == 'success') {
      // window.location.href = the_theme.url + '/thank-you';
    }

  });
});

$("#newsletter").submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var form_results = $("#form-results");

  form_results.html(" ");
  form_results.removeClass("alert");
  form_results.removeClass("alert-error");
  form_results.removeClass("alert-success");

  form.find(".btn").prop("disabled", true);

  var errors = [];

  // Validation
  if (form.find("input[name=email]").val() == "") {
    errors.push("The email field is required");
  }

  if (errors.length > 0) {
    var error_html = '<ul class="mb-0">';
    form_results.addClass("alert");
    form_results.addClass("alert-info");

    $.each(errors, function (index, value) {
      error_html += "<li>" + value + "</li>";
    });
    error_html += "</ul>";

    form_results.html(error_html);
    form.find(".btn").prop("disabled", false);
    return false;
  }

  var data = {
    action: 'do_ajax',
    fn: 'newsletter',
    data: form.serializeArray(),
    security: the_theme.ajax_nonce,
    siteurl: the_theme.url,
  };



  $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {

    response = JSON.parse(response);

    console.log(response);

    $("#form-results").hide(0);

    $(".formpwrap").fadeOut(function () {
      form_results.append(response);
      setTimeout(function () {
        $("#form-results").fadeIn();
      }, 600);
    });

    $(form).each(function () {
      this.reset();
    });

    form.find('.btn').prop('disabled', false);

    if (
      response ==
      '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>'
    ) {
      window.location.href = the_theme.url + "/thank-you-for-signing-up-for-our-newsletter/";
    }

  });
});

$("#contact").submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var form_results = $("#form-results");

  form_results.html(" ");
  form_results.removeClass("alert");
  form_results.removeClass("alert-error");
  form_results.removeClass("alert-success");

  form.find(".btn").prop("disabled", true);

  var errors = [];

  // Validation
  if (form.find("input[name=email]").val() == "") {
    errors.push("The email field is required");
  }


  if (errors.length > 0) {
    var error_html = '<ul class="mb-0">';
    form_results.addClass("alert");
    form_results.addClass("alert-info");

    $.each(errors, function (index, value) {
      error_html += "<li>" + value + "</li>";
    });
    error_html += "</ul>";

    form_results.html(error_html);
    form.find(".btn").prop("disabled", false);
    return false;
  }

  var data = {
    action: 'do_ajax',
    fn: 'contact',
    data: form.serializeArray(),
    security: the_theme.ajax_nonce,
    siteurl: the_theme.url,
  };



  $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {

    response = JSON.parse(response);

    console.log(response);

    $("#form-results").hide(0);

    $(".formpwrap").fadeOut(function () {
      form_results.append(response);
      setTimeout(function () {
        $("#form-results").fadeIn();
      }, 600);
    });

    $(form).each(function () {
      this.reset();
    });

    form.find('.btn').prop('disabled', false);

    if (
      response ==
      '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>'
    ) {
      window.location.href = the_theme.url + "/thank-you-for-contacting-us/";
    }

  });
});

$("#partnership").submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var form_results = $("#form-results");

  form_results.html(" ");
  form_results.removeClass("alert");
  form_results.removeClass("alert-error");
  form_results.removeClass("alert-success");

  form.find(".btn").prop("disabled", true);

  var errors = [];

  // Validation
  if (form.find("input[name=email]").val() == "") {
    errors.push("The email field is required");
  }


  if (errors.length > 0) {
    var error_html = '<ul class="mb-0">';
    form_results.addClass("alert");
    form_results.addClass("alert-info");

    $.each(errors, function (index, value) {
      error_html += "<li>" + value + "</li>";
    });
    error_html += "</ul>";

    form_results.html(error_html);
    form.find(".btn").prop("disabled", false);
    return false;
  }

  var data = {
    action: 'do_ajax',
    fn: 'partnership',
    data: form.serializeArray(),
    security: the_theme.ajax_nonce,
    siteurl: the_theme.url,
  };



  $.post(the_theme.url + '/wp-admin/admin-ajax.php', data, function (response) {

    response = JSON.parse(response);

    console.log(response);

    $("#form-results").hide(0);

    $(".formpwrap").fadeOut(function () {
      form_results.append(response);
      setTimeout(function () {
        $("#form-results").fadeIn();
      }, 600);
    });

    $(form).each(function () {
      this.reset();
    });

    form.find('.btn').prop('disabled', false);

    if (
      response ==
      '<p class="alert alert-success" id="contact-success">Your message was sent successfully!</p>'
    ) {
      window.location.href = the_theme.url + "/thank-you-for-your-inquiry/";
    }

  });
});
// form

// Load Images Async switch src attribute with data-lazysrc
// ---------------------------------------------------------------------------------------------------
function ReLoadImages() {
  $("img[data-lazysrc]").each(function() {
    $(this).attr("src", $(this).attr("data-lazysrc"));
  });
}
document.addEventListener("readystatechange", (event) => {
  if (event.target.readyState === "interactive") {
    //or at "complete" if you want it to execute in the most last state of window.
    ReLoadImages();
  }
});

// scroll to
// ---------------------------------------------------------------------------------------------------
$("[data-scroll-to]").click(function(e) {
  var $this = $(this),
    $toElement = $this.attr("data-scroll-to"),
    $focusElement = $this.attr("data-scroll-focus"),
    $offset = $this.attr("data-scroll-offset") * 1 || 0,
    $speed = $this.attr("data-scroll-speed") * 1 || 500;

  e.preventDefault();

  $("html, body").animate(
    {
      scrollTop: $($toElement).offset().top + $offset,
    },
    $speed
  );

  if ($focusElement) $($focusElement).focus();
});

// Hide top header when scroll down
// enable hidden nav bar
// const nav = document.querySelector(".top");
//   let lastScrollY = window.scrollY;


//   window.addEventListener("scroll", () => {
//     if (lastScrollY < window.scrollY) {
//       // console.log("we are going down");
//       nav.classList.add("top--hidden");
//     } else {
//       nav.classList.remove("top--hidden");
//     }

//     lastScrollY = window.scrollY;
//   });


jQuery(document).ready(function ($) {
  $('a.search-link.link').click(function (e) {
    e.preventDefault()
    $('#search-overlay').fadeIn();
    $('input#s').focus()
  });
  $('#close-search').click(function () {
    $('#search-overlay').fadeOut();
  });
});
  
// Filter
$("select.filterby").change(function () {
  var filters = $.map($("select.filterby").toArray(), function (e) {
    return $(e).val();
  }).join(".");
  $("div#FilterContainer").find("div").hide();
  $("div#FilterContainer").find("div." + filters).show();
});


// Product Sidebar
$(document).ready(function () {

  var acc = document.getElementsByClassName("accordion-toggle");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      /* Toggle between adding and removing the "active" class,
      to highlight the button that controls the panel */
      this.classList.toggle("active");

      /* Toggle between hiding and showing the active panel */
      var panel = this.nextElementSibling;
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    });
  }

});



// jQuery( '#account_creation_woo_null' ).on( 'submit', function(event) {
//   event.preventDefault();

//   let form_data = jQuery( this ).serializeArray();

//   console.log('f');

//   // Here we add our nonce (The one we created on our functions.php. WordPress needs this code to verify if the request comes from a valid source.
//   form_data.push( { "name" : "security", "value" : ajax_nonce } );

//   // Here is the ajax petition.
//   jQuery.ajax({
//       url : ajax_url, // Here goes our WordPress AJAX endpoint.
//       type : 'post',
//       data : form_data,
//       success : function( response ) {
//           // You can craft something here to handle the message return
//           console.log( response );
//       },
//       fail : function( err ) {
//           // You can craft something here to handle an error if something goes wrong when doing the AJAX request.
//           console.log( "There was an error: " + err );
//       }
//   });
   
//   // This return prevents the submit event to refresh the page.
//   return false;
// });


$(document).ready(function(){

  let step1 = $('.fieldset_container.step-1'),
      step2 = $('.fieldset_container.step-2'),
      step3 = $('.fieldset_container.step-3');
  
  $('.fieldset_container.step-2').hide();
  $('.fieldset_container.step-3').hide();

  $('.rl-next-step, .steps__box').on('click',function(event){

    let screenSelect = $(this).attr('data-screen');
    let screenSwitch;
    console.log(screenSelect)


    $('html, body').animate({
        scrollTop: $('#account_creation_woo').offset().top - 120 
    }, 'slow');

    $('.fieldset_container').slideUp();
    $('.steps__box').removeClass('steps__box--active');


    switch (screenSelect) {
      case 'step1':
        screenSwitch = step1;
        break;
      case 'step2':
        screenSwitch = step2;
        break;
      case 'step3':
        screenSwitch = step3;
        break;
    
      default:
        break;
    }

    console.log(screenSelect)

    setTimeout(function(){
      $('.box_'+screenSelect).addClass('steps__box--active');
      $(screenSwitch).show();
    },300)
  })

  $('.rl-button-submit').on('click',function(){
    $('#account_creation_woo').submit()
  })
});